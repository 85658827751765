import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Equipment } from './equipment';

@Injectable({
  providedIn: 'root'
})
export class PlaceUpdate extends Parse.Object {

  constructor() {
    super('PlaceUpdate');
  }

  get title(): string {
    return this.get('title');
  }

  set title(val) {
    this.set('title', val);
  }

  get description(): string {
    return this.get('description');
  }

  set description(val) {
    this.set('description', val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get website(): string {
    return this.get('website');
  }

  set website(val) {
    this.set('website', val);
  }

  get address(): string {
    return this.get('address');
  }

  set address(val) {
    this.set('address', val);
  }

  get category() {
    return this.get('category');
  }

  set category(val) {
    this.set('category', val);
  }

  get image() {
    return this.get('image');
  }

  set image(val) {
    this.set('image', val);
  }

  get images() {
    return this.get('images');
  }

  set images(val) {
    this.set('images', val);
  }

  get location() {
    return this.get('location');
  }

  set location(val) {
    var geoPoint = new Parse.GeoPoint({
      latitude: val.lat,
      longitude: val.lng
    });
    this.set('location', geoPoint);
  }

  get imageThumb() {
    return this.get('imageThumb');
  }

  get status() {
    return this.get('status');
  }

  get facebook() {
    return this.get('facebook');
  }

  get youtube() {
    return this.get('youtube');
  }

  get instagram() {
    return this.get('instagram');
  }

  get longDescription() {
    return this.get('longDescription');
  }

  set equipments(val: any) {
    this.set('equipments', val);
  }

  get equipments() {
    return this.get('equipments');
  }

  get place(): any {
    return this.get('place');
  }

  set place(val) {
    this.set('place', val);
  }

  get isAccessible(): boolean {
    return this.get('isAccessible');
  }

  set isAccessible(val: boolean) {
    this.set('isAccessible', val);
  }

}

Parse.Object.registerSubclass('PlaceUpdate', PlaceUpdate);
